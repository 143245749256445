<template>
  <div class="p-4">
    <CollapseContainer
      title="Product Catalog Configuration"
      variant="secondary"
      border
      :open="true"
    >
      <div
        v-if="getProductInstanceLoading"
        class="d-flex justify-content-center align-items-center"
        style="height: 100px"
      >
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <div v-else>
        <ProductListNewEmptyCatalog v-if="!getProductInstance" />
        <div
          v-else
          class="p-2"
        >
          <ul>
            <li>
              Catalog's name: {{ getProductInstance.catalogConnector.name }}
            </li>
            <li v-if="!!getProductInstance.catalogConnector.apiUri">
              Brand API URL: {{ getProductInstance.catalogConnector.apiUri }}
            </li>
            <li v-if="!!getProductInstance.catalogConnector.template">
              Template: {{ getProductInstance.catalogConnector.template }}
            </li>
          </ul>
        </div>
      </div>
    </CollapseContainer>
    <section
      v-if="getProductInstance"
      class="mt-3"
    >
      <FireButtonGroup
        :options="options"
        :hide-buttons="false"
      />
    </section>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ProductListNewEmptyCatalog from './ProductListNewEmptyCatalog'
  import CollapseContainer from '@/components/containers/CollapseContainer'
  import FireButtonGroup from '@/components/buttons/FireButtonGroup'

  export default {
    name: 'ProductListNew',
    components: {
      ProductListNewEmptyCatalog,
      CollapseContainer,
      FireButtonGroup,
    },
    data() {
      return {
        isCatalogConfigured: false,
        options: [
          { routeName: 'prescription-product-list-new', text: 'PRODUCTS' },
          { routeName: 'prescription-product-list-new-looks', text: 'LOOKS' },
          {
            routeName: 'prescription-product-list-new-contents',
            text: 'CONTENTS',
          },
        ],
      }
    },
    computed: {
      ...mapGetters('products', [
        'getProductInstance',
        'getProductInstanceLoading',
      ]),
    },
  }
</script>

<style>
  ul {
    margin-top: 1rem;
  }
</style>
