var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"key-value-table"},_vm._l((_setup.editingValues),function(line,index){return _c('b-row',{key:index,staticClass:"mt-2"},[(line.isVisible)?_c('b-col',{attrs:{"cols":_setup.props.keyCols}},[_c('b-row',[_c('b-col',[_vm._v(" "+_vm._s(line.label)+" ")])],1),_c('b-row',[_c('b-col',[(line.key)?_c('p',{staticClass:"text-muted small"},[_vm._v(" id: "+_vm._s(line.key)+" ")]):_vm._e()])],1)],1):_vm._e(),(line.isVisible)?_c('b-col',{staticClass:"key-value-table"},[[(line.type === 'link')?_c('div',[(_setup.editingIndex !== index)?_c('div',{staticClass:"d-flex align-items-center"},[_c(_setup.EmptyPlaceholder,{attrs:{"value":_setup.getValue(line)}},[_c('a',{staticClass:"key-value-table--link ellipsis-3",attrs:{"href":_setup.getValue(line),"target":"_blank","title":_setup.getValue(line)}},[_vm._v(_vm._s(_setup.getValue(line)))])]),(
                _setup.props.showEditableFields &&
                line.isEditable &&
                _setup.editingIndex !== index
              )?_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"pencil-fill","type":"button"},on:{"click":function($event){return _setup.toggleEdit(index)}}}):_vm._e()],1):(index === _setup.editingIndex)?_c('div',{staticClass:"d-flex align-items-center"},[[_c('input',{directives:[{name:"model",rawName:"v-model",value:(line.value),expression:"line.value"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(line.value)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _setup.saveEdit(line)},"input":function($event){if($event.target.composing)return;_vm.$set(line, "value", $event.target.value)}}}),_c(_setup.SendOrCancelButtons,{on:{"saveEdit":function($event){return _setup.saveEdit(line)},"cancelEdit":function($event){return _setup.cancelEdit(index)}}})]],2):_vm._e(),(_setup.showOriginal(line))?_c('div',[_c('p',{staticClass:"text-muted small font-italic"},[_vm._v(" "+_vm._s(_setup.getCatalogValue(line))+" ")])]):_vm._e()]):(line.type === 'image')?_c('div',[(_setup.editingIndex !== index)?_c('div',{staticClass:"d-flex align-items-center"},[_c(_setup.EmptyPlaceholder,{attrs:{"value":_setup.getValue(line)}},[_c('img',{staticClass:"key-value-table--image",attrs:{"src":_setup.getValue(line)}})]),(
                _setup.props.showEditableFields &&
                line.isEditable &&
                _setup.editingIndex !== index
              )?_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"pencil-fill","type":"button"},on:{"click":function($event){return _setup.toggleEdit(index)}}}):_vm._e()],1):(index === _setup.editingIndex)?[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('b-form-input',{staticClass:"col col-9",attrs:{"type":"text","placeholder":"Add the url of the image"},model:{value:(line.value),callback:function ($$v) {_vm.$set(line, "value", $$v)},expression:"line.value"}}),_c(_setup.PackshotPreview,{staticClass:"col col-3",attrs:{"name":"PSP1","src":_setup.getValue(line)}})],1),_c(_setup.SendOrCancelButtons,{on:{"saveEdit":function($event){return _setup.saveEdit(line)},"cancelEdit":function($event){return _setup.cancelEdit(index)}}})],1)]:_vm._e(),_c('div',{staticClass:"row"},[(line.catalogValue && _setup.showOriginal(line))?_c('div',{staticClass:"col col-4"},[_c('p',{staticClass:"text-muted small font-italic"},[_vm._v("original:")]),_c(_setup.PackshotPreview,{attrs:{"name":"PSP2","src":line.catalogValue}})],1):_vm._e()])],2):(line.type === 'boolean')?_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"d-flex flex-column align-items-start"},[(_setup.editingIndex !== index)?_c('div',{staticClass:"d-flex align-items-center"},[_c('b-badge',{staticClass:"ml-2",attrs:{"variant":line.value ? 'success' : 'danger'}},[_vm._v(" "+_vm._s(_setup.getValue(line) ? 'Yes' : 'No')+" ")]),(
                  _setup.props.showEditableFields &&
                  line.isEditable &&
                  _setup.editingIndex !== index
                )?_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"pencil-fill","type":"button"},on:{"click":function($event){return _setup.toggleEdit(index)}}}):_vm._e()],1):_vm._e(),(index === _setup.editingIndex)?_c('div',[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-select',{staticClass:"ml-2",attrs:{"options":[
                    { value: true, text: 'Yes' },
                    { value: false, text: 'No' },
                  ]},model:{value:(line.value),callback:function ($$v) {_vm.$set(line, "value", $$v)},expression:"line.value"}}),_c(_setup.SendOrCancelButtons,{on:{"saveEdit":function($event){return _setup.saveEdit(line)},"cancelEdit":function($event){return _setup.cancelEdit(index)}}})],1)]):_vm._e(),(line.value !== line.catalogValue)?_c('div',{staticClass:"mt-2"},[_c('p',{staticClass:"text-muted small font-italic"},[_vm._v(" "+_vm._s(line.value ? (line.catalogValue ? 'Yes' : 'No') : '')+" ")])]):_vm._e()])]):(line.type === 'tags')?_c('div',{staticClass:"d-flex align-items-center"},[(_setup.editingIndex !== index)?_c(_setup.EmptyPlaceholder,{attrs:{"value":line.value}},[(line.value)?_c('div',_vm._l((line.value),function(tag,tagIndex){return _c('b-badge',{key:tagIndex,staticClass:"ml-2"},[_vm._v(" "+_vm._s(tag)+" ")])}),1):_c('div',[_c('span',{staticClass:"text-muted"},[_vm._v("No "+_vm._s(line.label)+" yet")])])]):_vm._e(),(
              _setup.props.showEditableFields &&
              line.isEditable &&
              _setup.editingIndex !== index
            )?_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"pencil-fill","type":"button"},on:{"click":function($event){return _setup.toggleEdit(index)}}}):(index === _setup.editingIndex)?[_c('div',{staticClass:"d-flex align-items-center"},[_c(_setup.Multiselect,{attrs:{"multiple":true,"options":_vm.multiselectOptions[line.key.toLowerCase()],"placeholder":"Select a new tag","text-no-options":"no options available","create-option":"false"},model:{value:(line.value),callback:function ($$v) {_vm.$set(line, "value", $$v)},expression:"line.value"}},[_vm._v(" "+_vm._s(line.value)+" ")]),_c(_setup.SendOrCancelButtons,{on:{"saveEdit":function($event){return _setup.saveEdit(line)},"cancelEdit":function($event){return _setup.cancelEdit(index)}}})],1)]:_vm._e()],2):(line.type === 'number')?_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"d-flex align-items-center"},[(_setup.editingIndex !== index)?_c(_setup.EmptyPlaceholder,{class:{
                'text-info font-weight-bold':
                  line.value && line.value !== line.catalogValue,
              },attrs:{"value":_setup.getValue(line)}}):_vm._e(),(
                _setup.props.showEditableFields &&
                line.isEditable &&
                _setup.editingIndex !== index
              )?_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"pencil-fill","type":"button"},on:{"click":function($event){return _setup.toggleEdit(index)}}}):_vm._e()],1),(index === _setup.editingIndex)?[_c('div',{staticClass:"d-flex align-items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(line.value),expression:"line.value"}],staticClass:"form-control",attrs:{"type":"number"},domProps:{"value":(line.value)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _setup.saveEdit(line)},"input":function($event){if($event.target.composing)return;_vm.$set(line, "value", $event.target.value)}}}),_c(_setup.SendOrCancelButtons,{on:{"saveEdit":function($event){return _setup.saveEdit(line)},"cancelEdit":function($event){return _setup.cancelEdit(index)}}})],1)]:_vm._e(),(_setup.showOriginal(line))?_c('div',[_c('p',{staticClass:"text-muted small font-italic"},[_vm._v(" "+_vm._s(_setup.getCatalogValue(line))+" ")])]):_vm._e()],2):(line.type === 'array')?_c('div',{staticClass:"d-flex align-items-center"},_vm._l((line.value),function(item){return _c('div',{key:item.type,staticClass:"d-flex align-items-center"},[_c('b-badge',{staticClass:"ml-2",attrs:{"variant":item.available ? 'success' : 'danger'}},[_vm._v(" "+_vm._s(item.type)+" : "+_vm._s(item.available)+" ")])],1)}),0):_c('div',[_c('div',{staticClass:"d-flex align-items-center"},[(_setup.editingIndex !== index)?_c(_setup.EmptyPlaceholder,{class:{
                'text-info font-weight-bold':
                  line.value && line.value !== line.catalogValue,
              },attrs:{"value":_setup.getValue(line)}}):_vm._e(),(
                _setup.props.showEditableFields &&
                line.isEditable &&
                _setup.editingIndex !== index
              )?_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"pencil-fill","type":"button"},on:{"click":function($event){return _setup.toggleEdit(index)}}}):_vm._e()],1),(index === _setup.editingIndex)?[_c('div',{staticClass:"d-flex align-items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(line.value),expression:"line.value"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(line.value)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _setup.saveEdit(line)},"input":function($event){if($event.target.composing)return;_vm.$set(line, "value", $event.target.value)}}}),_c(_setup.SendOrCancelButtons,{on:{"saveEdit":function($event){return _setup.saveEdit(line)},"cancelEdit":function($event){return _setup.cancelEdit(index)}}})],1)]:_vm._e(),(_setup.showOriginal(line))?_c('div',[_c('p',{staticClass:"text-muted small font-italic"},[_vm._v(" "+_vm._s(_setup.getCatalogValue(line))+" ")])]):_vm._e()],2)]],2):_vm._e()],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }