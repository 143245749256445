import { z } from 'zod'

export const FacetsSchema = z.array(
  z.object({
    key: z.enum([
      'BrandCode',
      'Status',
      'Calibration/IsActive',
      'Calibration/IsValidated',
      'Calibration/Exists',
    ]),
    values: z.array(
      z.object({
        count: z.number(),
        value: z.any(),
      }),
    ),
  }),
)

export type Facets = z.infer<typeof FacetsSchema>

export const GetProductsByCatalogResultSchema = z.object({
  success: z.boolean(),
  facets: FacetsSchema,
  pagination: z.object({
    nbResults: z.number(),
    nbPages: z.number(),
    currentPage: z.number(),
  }),
  items: z.array(
    z.object({
      sku: z.string(),
      name: z.string().optional().nullable(),
      packshotImageUrl: z.string().optional().nullable(),
      status: z.string().optional().nullable(),
    }),
  ),
})

export type GetProductsByCatalogResult = z.infer<
  typeof GetProductsByCatalogResultSchema
>

export const BeautyHubEnvSchema = z.union([
  z.literal('dev'),
  z.literal('staging'),
  z.literal('production'),
])

export type BeautyHubEnv = z.infer<typeof BeautyHubEnvSchema>

export const BeautyHubTenantSchema = z.enum(['lorealsa', 'modiface'])

export type BeautyHubTenant = z.infer<typeof BeautyHubTenantSchema>
