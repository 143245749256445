import Vue from 'vue'
import { doesDocExist } from '@/service/FirebaseService'
import Vuex, { ActionTree, GetterTree, MutationTree } from 'vuex'

import db from '@/firebase/firestore'

import { compareValues } from '@/service/UtilsService'
import { bmdmValuesConverter } from '../types/BmdmValue'
import { addDocToCollection } from '../service/FirebaseService'
import {
  RESOURCE,
  RESOURCES,
  resourcesConfig,
  getResourceStateKey,
  filterHasMonoTenant,
  filterHasMultiTenant,
  fetchCollection,
  getDocumentsInCollectionLoader,
  FetchCollectionInput,
} from '../service/ResourceService'
import {
  Application,
  Country,
  Customer,
  BMDMValue,
  Brand,
  Criteria,
  CrmCode,
  Flag,
  Env,
  Scope,
  Tenant,
  Touchpoint,
  Type,
} from '@/models'
import { UserState } from './modules/user'
import { AppRouteState } from './modules/approutes'
import { AppVersionState } from './modules/appversions'
import { NavigationState } from './modules/navigation'
import { PrescriptionState } from './modules/prescriptions'
import { ProductsState } from './modules/products'
import { ScopeState } from './modules/scopes'

const noop = () => ({})
const documentsInCollectionLoader = getDocumentsInCollectionLoader()

const getCollectionScope = (getters) => (collectionName) =>
  getters.getUserScopeCollection(collectionName)

// Load all modules.
function loadModules() {
  const context = require.context('./modules', false, /([a-z_]+)\.[tj]s$/i)

  const modules = context
    .keys()
    .map((key) => ({ key, name: key.match(/([a-z_]+)(.store)?\.[tj]s$/i)[1] }))
    .reduce(
      (modules, { key, name }) => ({
        ...modules,
        [name]: context(key).default,
      }),
      {},
    )

  return { context, modules }
}

const { context, modules } = loadModules()

Vue.use(Vuex)

type LoadingInformations = {
  title?: string
  percentage?: number
  bar?: boolean
  show?: boolean
  channel?: string
}

export type RootState = IndexState & {
  user: UserState
  approutes: AppRouteState
  appversions: AppVersionState
  navigation: NavigationState
  prescriptions: PrescriptionState
  products: ProductsState
  scopes: ScopeState
}

export type IndexState = {
  isDBLoaded: boolean
  isAppRouteLoaded: boolean
  isAppLoading: boolean
  cptAppLoading: number
  showLiabilityPopIn: boolean
  showPhoneNumberPopIn: boolean
  settings: {
    isMaintenanceModeOn: boolean
    lastReleaseDate: string
    retailersTermsOfUseLastModified: string
    version: number
    unsubscribe: () => void
  }
  loadingInformation: {
    title: string
    percentage: number
    bar: boolean
    show: boolean
    channels: Set<string>
  }
  tenants: Tenant[]
  customers: Customer[]
  applications: Application[]
  countries: Country[]
  envs: Env[]
  touchpoints: Touchpoint[]
  types: Type[]
  brands: Brand[]
  criterias: Criteria[]
  flags: Flag[]
  commercialflags: Flag[]
  heroingredients: Flag[]
  crmcodes: CrmCode[]
  bmdmCountries: BMDMValue[]
  searchMenu: string | null
  fetchedResources: Record<RESOURCE, boolean>
}

export const state: IndexState = {
  isDBLoaded: false,
  isAppRouteLoaded: false,
  isAppLoading: false,
  cptAppLoading: 0,
  showLiabilityPopIn: false,
  showPhoneNumberPopIn: false,
  settings: {
    isMaintenanceModeOn: false,
    lastReleaseDate: null,
    retailersTermsOfUseLastModified: null,
    version: 0,
    unsubscribe: null,
  },
  loadingInformation: {
    title: null,
    percentage: 0,
    bar: false,
    show: false,
    channels: new Set(),
  },
  tenants: [],
  customers: [],
  applications: [],
  countries: [],
  envs: [],
  touchpoints: [],
  types: [],
  brands: [],
  criterias: [],
  flags: [],
  commercialflags: [],
  heroingredients: [],
  crmcodes: [],
  bmdmCountries: [],
  searchMenu: null,
  fetchedResources: {
    tenants: false,
    customers: false,
    applications: false,
    countries: false,
    envs: false,
    touchpoints: false,
    types: false,
    brands: false,
    criterias: false,
    flags: false,
    heroingredients: false,
    commercialflags: false,
    crmcodes: false,
    appversions: false,
  },
}

export const mutations: MutationTree<IndexState> = {
  dbLoaded(state, value: boolean) {
    state.isDBLoaded = value
  },

  appRouteLoaded(state, value: boolean) {
    state.isAppRouteLoaded = value
  },

  SET_SETTINGS: (
    state,
    {
      unsubscribe,
      isMaintenanceModeOn,
      lastReleaseDate,
      retailersTermsOfUseLastModified,
      version,
    }: {
      unsubscribe: () => void
      isMaintenanceModeOn: boolean
      lastReleaseDate: string
      retailersTermsOfUseLastModified: string
      version: number
    },
  ) => {
    if (unsubscribe) state.settings.unsubscribe = unsubscribe

    if (lastReleaseDate) state.settings.lastReleaseDate = lastReleaseDate

    if (retailersTermsOfUseLastModified)
      state.settings.retailersTermsOfUseLastModified =
        retailersTermsOfUseLastModified

    if (version) state.settings.version = version

    if (![null, undefined].includes(isMaintenanceModeOn))
      state.settings.isMaintenanceModeOn = isMaintenanceModeOn
  },

  setSearchMenu(state, value: string | null) {
    state.searchMenu = value
  },

  appLoading(state, value: boolean) {
    if (value) {
      state.cptAppLoading++
    } else {
      state.cptAppLoading--
    }

    // To avoid the fact to have negative values.
    if (state.cptAppLoading < 0) state.cptAppLoading = 0
    // If value true, else if not other loader set false
    state.isAppLoading = value || state.cptAppLoading > 0
  },

  SET_LOADING_INFORMATIONS(
    state,
    { title, percentage, bar, show, channel = 'default' }: LoadingInformations,
  ) {
    state.loadingInformation.channels.add(channel)

    if (title) {
      state.loadingInformation.title = title
    }

    if (![null, undefined].includes(show)) {
      if (!show) {
        state.loadingInformation.channels.delete(channel)
        if (state.loadingInformation.channels.size === 0)
          state.loadingInformation.show = false
      } else {
        state.loadingInformation.show = true
      }
    }

    if (![null, undefined].includes(percentage)) {
      state.loadingInformation.percentage = percentage
    }

    if (![null, undefined].includes(bar)) {
      state.loadingInformation.bar = bar
    }
  },

  setLoadingPercentage(
    state,
    {
      totalSteps,
      currentStep,
    }: {
      totalSteps: number
      currentStep: number
    },
  ) {
    const percentage = (currentStep / totalSteps) * 100

    state.loadingInformation.percentage = percentage
  },

  setState(
    state,
    payload: {
      key: string
      value: any
    },
  ) {
    state[payload.key] = payload.value
  },

  setFetchedResourceState(
    state,
    { key, fetched }: { key: RESOURCE; fetched: boolean },
  ) {
    state.fetchedResources[key] = fetched
  },

  addRootStateValue(
    state,
    {
      newData,
      key,
    }: {
      newData: any
      key: string
    },
  ) {
    if (!state[key] || !newData || !key) return

    state[key].push(newData)
  },

  updateRootStateValue(
    state,
    {
      id,
      newData,
      key,
    }: {
      id: string
      newData: any
      key: string
    },
  ) {
    if (!state[key] || !newData || !key || !id) return

    const keyIndex = state[key].findIndex((property) => property.id === id)
    state[key].splice(keyIndex, 1, newData)
  },

  deleteRootStateValue(
    state,
    {
      id,
      key,
    }: {
      id: string
      key: string
    },
  ) {
    if (!state[key] || !id || !key) return

    const keyIndex = state[key].findIndex((property) => property.id === id)
    state[key].splice(keyIndex, 1)
  },

  SET_SHOW_PHONE_NUMBER_POP_IN(state, value: boolean) {
    state.showPhoneNumberPopIn = value
  },

  SET_SHOW_LIABILITY_POP_IN(state, value: boolean) {
    state.showLiabilityPopIn = value
  },
}

export const actions: ActionTree<IndexState, RootState> = {
  async initApp({ dispatch }) {
    await dispatch('bindDB')
  },

  unbindApp({ commit, dispatch }) {
    dispatch('unbindAppVersions')
    dispatch('unbindDB')
    dispatch('unbindAppRoutes')
    dispatch('scopesrolesgroups/unbindScopesRolesGroups')
    dispatch('unsetCurrentUser')
    commit('dbLoaded', false)
  },

  async loadResources(
    { commit, dispatch, state, getters },
    {
      resourceNames,
      afterEachFn,
    }: {
      resourceNames: RESOURCE[]
      afterEachFn?: () => void
    },
  ) {
    const notAllowedResourceNames = resourceNames.filter((n) => !RESOURCES[n])
    if (notAllowedResourceNames.length)
      throw new Error(
        `Not allowed resources: ${notAllowedResourceNames.join(', ')}`,
      )

    const scopeTenants = getters.getUserScopeCollection('tenants')

    const sortFn = compareValues('displayName')
    const scopeFn = getCollectionScope(getters)
    const afterFn = afterEachFn || noop

    const getCollectionConfig = (
      collectionName: string,
      scopeTenants: Scope,
    ): FetchCollectionInput<any> => {
      const noScopeCollectionNames = [
        'types',
        'criterias',
        'crmcodes',
        'flags',
        'heroingredients',
        'commercialflags',
      ]

      if (collectionName === 'criterias') {
        return { collectionName, documentsInCollectionLoader, afterFn }
      } else if (noScopeCollectionNames.includes(collectionName)) {
        return {
          collectionName,
          documentsInCollectionLoader,
          getActiveDocuments: true,
          sortFn,
          afterFn,
        }
      } else {
        let filterFn
        if (collectionName === 'customers')
          filterFn = filterHasMonoTenant(scopeTenants)
        else if (collectionName === 'brands') {
          filterFn = filterHasMultiTenant(scopeTenants)
        }

        return {
          collectionName,
          documentsInCollectionLoader,
          getActiveDocuments: true,
          scopeFn,
          sortFn,
          afterFn,
          filterFn,
        }
      }
    }

    const loadResource = async (resourceName: string) => {
      const resourceConfig = resourcesConfig[resourceName]
      const { collectionName } = resourceConfig

      const stateKey = getResourceStateKey(resourceConfig)
      if (state.fetchedResources[stateKey]) return

      let results
      if (resourceName === RESOURCES.APP_VERSIONS) {
        await dispatch('bindAppVersions')
        afterFn()
      } else {
        results = await fetchCollection(
          getCollectionConfig(collectionName, scopeTenants),
        )
      }

      const noUpdateStateResourceNames = [RESOURCES.APP_VERSIONS]
      if (!noUpdateStateResourceNames.includes(resourceName)) {
        commit('setState', { key: stateKey, value: results })
      }

      commit('setFetchedResourceState', { key: stateKey, fetched: true })
    }

    return Promise.all(
      resourceNames.map((resourceName) => loadResource(resourceName)),
    )
  },

  async bindDB({ commit, dispatch }) {
    dispatch('setLoadingInformations', {
      title: 'Firecamp initialization ...',
      show: true,
      bar: true,
    })

    const settingsUnsubscribe = db
      .doc('settings/setting-001')
      .onSnapshot((settingsSnapshot) => {
        const data = settingsSnapshot.data()
        commit('SET_SETTINGS', {
          ...data,
          isMaintenanceModeOn: data?.maintenanceMode,
        })
      })

    commit('SET_SETTINGS', { unsubscribe: settingsUnsubscribe })
    commit('dbLoaded', true)
    dispatch('setLoadingInformations', { title: null, show: false })
  },

  unbindDB({ commit }) {
    const stateKeys = Object.values(resourcesConfig).map((r) =>
      getResourceStateKey(r),
    )

    stateKeys.forEach((key) => {
      commit('setState', { key, value: [] })
      commit('setFetchedResourceState', { key, fetched: false })
    })

    documentsInCollectionLoader.clearAll()
  },

  async loadBmdmCountries({ commit }) {
    // <any> is used to avoid firestore sdk issue with typescript
    const bmdmCountries = (
      await db
        .collection(
          'bmdmreferential/PRODUCTINFOREF-1.3/entities/countries/values',
        )
        .withConverter(<any>bmdmValuesConverter)
        .get()
    ).docs.map((d) => d.data())

    commit('setState', { key: 'bmdmCountries', value: bmdmCountries })
  },

  async createApp(_, { appDetails }: { appDetails: Application }) {
    const feedback = {
      state: '',
      msg: '',
    }
    // Check if app already exists, if yes, return error
    if (await doesDocExist('applications', appDetails.id)) {
      feedback.state = 'warning'
      feedback.msg = 'this app already exists.'

      return feedback
    }

    // Create new app
    await addDocToCollection(
      null,
      'applications',
      {
        description: appDetails.description,
        displayName: appDetails.displayName,
      },
      null,
      appDetails.id,
    )

    feedback.state = 'success'
    feedback.msg = `${appDetails.displayName} has been created successfuly.`

    return feedback
  },

  setLoadingInformations({ commit }, payload: LoadingInformations) {
    commit('SET_LOADING_INFORMATIONS', payload)
  },

  setShowPhoneNumberPopIn({ commit }, value: boolean) {
    commit('SET_SHOW_PHONE_NUMBER_POP_IN', value)
  },

  setShowLiabilityPopIn({ commit }, value: boolean) {
    commit('SET_SHOW_LIABILITY_POP_IN', value)
  },
}

export const getters: GetterTree<IndexState, RootState> = {
  getCustomers: (state) => (tenantId: string) => {
    const customers = []

    state.customers
      .filter((customer) => !!customer?.tenant?.id)
      .forEach((customer) => {
        if (customer?.tenant?.id == tenantId) customers.push(customer)
      })

    return customers
  },

  getSearchMenu: (state) => {
    return state.searchMenu
  },

  getRootStateValueById:
    (state) =>
    ({ id, stateKey }: { id: string; stateKey: string }) => {
      if (!id || !stateKey || !state[stateKey]) return {}
      return state[stateKey].find((stateValue) => stateValue.id === id)
    },

  isMaintenanceModeOn: (state) => {
    return state.settings.isMaintenanceModeOn
  },

  getLastReleaseDate: (state) => {
    return state.settings.lastReleaseDate
  },

  getRetailersTermsOfUseLastModified: (state) => {
    return state.settings.retailersTermsOfUseLastModified
  },

  getVersion: (state) => {
    return state.settings.version
  },

  getCollection: (state) => (collection: keyof IndexState) => {
    return state[collection]
  },

  getApplicationPrescriptionAutoTemplates:
    (state) => (applicationId: string) => {
      const application = state.applications.find(
        (application) => application.id === applicationId,
      )
      return application.prescriptionAutomationTemplates
    },
}

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules,
})

if (module.hot) {
  // Hot reload whenever any module changes.
  module.hot.accept(context.id, () => {
    const { modules } = loadModules()

    store.hotUpdate({
      modules,
    })
  })
}

Vue.prototype.$store = store

export default store
