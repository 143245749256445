import DOMPurify from 'dompurify'

export function firstCharacterToUppercase(string: string): string {
  if (!string || string?.length === 0) return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function formatAssetLink(assetLink: string): string {
  const date = `?${Date.now()}`
  return `${process.env.VUE_APP_AZURE_STORAGE_URL}${assetLink}${date}`
}

export function updateVersion(version: string | number): string | number {
  switch (typeof version) {
    case 'string':
      return version.includes('.')
        ? version
            .split('.')
            .map(
              (current, index, array) =>
                index + 1 === array.length
                  ? (parseInt(current, 10) + 1).toString()
                  : current,
              [],
            )
            .join('.')
        : parseInt(version, 10) + 1
    case 'number':
      return version + 1
    default:
      return 1
  }
}

export function pluralToSingular(string: string): string {
  return string.endsWith('ies')
    ? string.slice(0, -3) + 'y'
    : string.slice(0, -1)
}

export function formatStringToId(string: string): string {
  return string
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replaceAll(' ', '_')
    .replace(/(__)|(--)+/g, '_')
    .replace(/(^_+|_+$)/, '')
}

export function getObjectFromHierarchy<TObject>(
  object: TObject,
  hierarchy: string[],
): TObject {
  if (hierarchy) {
    return hierarchy.reduce((subObject, level) => {
      if (!subObject[level]) return {}
      return subObject[level]
    }, object)
  }
  return object
}

export function setObjectInHierarchy<TObject, TValue>(
  object: TObject,
  hierarchy: string[],
  id: string,
  value: TValue,
): void {
  if (object) {
    if (hierarchy) {
      hierarchy.forEach((level) => {
        if (!object[level]) object[level] = {}
        object = object[level]
      })
    }
    object[id] = value
  }
}

export function convertStringToValue<T>(value: T): T | number | boolean {
  if (!isNaN(value as number) && ![true, false].includes(value as boolean)) {
    return +value
  } else if (value === 'true') {
    return true
  } else if (value === 'false') {
    return false
  }
  return value
}

export function isValidUrl(url: string): boolean {
  return /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_+.~#?&/=]*)$/.test(
    url,
  )
}

/* Based on ticket STRY0438587, only <a> tag are allowed in the sanitized html content for now.
This defaultSanitizeOptions can be updated if more tags are allowed in the future.
Or the options can be passed as a parameter to the function sanitizedHtmlContent. */
const defaultSanitizeOptions = {
  ALLOWED_TAGS: ['a'],
  ADD_ATTR: ['target'],
}
/**
 * Sanitizes the provided HTML content.
 *
 * @param htmlContent - The HTML content to be sanitized.
 * @param options - Optional configuration options for sanitization.
 * @returns The sanitized HTML content.
 */
export function sanitizedHtmlContent(
  htmlContent: string,
  options: { [key: string]: any } = {},
): string {
  if (!htmlContent) return ''
  const sanitizeOptions = {
    ...defaultSanitizeOptions,
    ...options,
  }
  return DOMPurify.sanitize(htmlContent, sanitizeOptions)
}
