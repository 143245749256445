import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import {
  PRESCRIPTION_ERROR_NO_DEFAULT_PROFILE,
  PRESCRIPTION_ERROR_MORE_THAN_ONE_PROFILE,
} from '@/constants/ErrorConstants'

export const PrescriptionMixin = {
  data() {
    return {
      loading: true,
      removeNotification: null,
    }
  },

  computed: {
    ...mapState({
      currentRoute: (state) => state.approutes.currentRoute,
      isAppRouteLoaded: (state) => state.isAppRouteLoaded,
      isDBLoaded: (state) => state.isDBLoaded,
      isPrescriptionLoaded: (state) => state.prescriptions.isLoaded,
    }),

    ...mapGetters({
      getAppRoute: 'getAppRoute',
      getDefaultProfiles: 'getDefaultProfiles',
      prescription: 'prescriptionPath',
    }),

    currentRouteId() {
      return this.appDetails?.id
    },

    currentEnvironmentId() {
      return this.appDetails?.environment?.id
    },

    title() {
      if (!this.appDetails) return this.$route.meta?.displayName ?? ''
      const { customer, application } = this.appDetails
      const title =
        [customer, application]
          .reduce((acc, current) => `${acc} ${current?.displayName}`, '')
          .trim() + ` - v${this.appDetails?.appversion?.meta?.version}`
      return title ?? this.$route.meta?.displayName
    },

    subtitle() {
      let title = null
      if (!this.appDetails) return null
      const { type, country, touchpoint, environment } = this.appDetails
      title = [type, country, touchpoint, environment]
        .reduce(
          (acc, current, index) =>
            `${acc} ${index == 0 ? '' : '-'} ${current?.displayName}`,
          '',
        )
        .trim()
      return title ?? null
    },

    appDetails() {
      return this.getAppRoute(this.$route?.params?.app_id)
    },
  },

  beforeCreate() {
    if (!this.$route.params.app_id && !this.prescription) {
      this.$router.push({ name: 'homepage' })
    }
  },

  beforeRouteLeave(to, from, next) {
    if (
      !to.matched.some(({ name }) =>
        ['prescription', 'edit-app'].includes(name),
      )
    ) {
      this.resetPrescriptionState()
      this.unbindProducts()
      this.unsetCurrentRoute()
    }
    next()
  },

  watch: {
    isPrescriptionLoaded(newValue) {
      this.loading = !newValue
    },

    loading(newLoadingValue) {
      this.emitLoading(newLoadingValue)
    },
  },

  mounted() {
    if (this.isPrescriptionLoaded) this.loading = false
  },

  methods: {
    ...mapActions([
      'getActivePrescription',
      'setCurrentRouteById',
      'unsetCurrentRoute',
      'bindAppRoutes',
    ]),

    ...mapActions({
      unbindProducts: 'products/unbindProducts',
    }),

    ...mapMutations(['resetPrescriptionState', 'setPrescriptionLoaded']),

    async initState() {
      await this.bindAppRoutes()

      if (this.appDetails && this.currentRouteId && !this.isAppRouteLoaded) {
        await this.setCurrentRouteById({currentRouteId: this.currentRouteId, routeName: this.$route.name})
      }

      if ([null, undefined].includes(this.appDetails)) {
        this.$router.push({
          name: 'error-page',
          params: { from: encodeURIComponent(this.$route.path) },
        })
        return
      }

      if (
        !this.isPrescriptionLoaded &&
        this.isAppRouteLoaded &&
        this.appDetails &&
        this.currentRouteId
      ) {
        try {
          await this.getActivePrescription({
            currentRouteId: this.currentRouteId,
          })
          await this.checkDefaultProfileState()
        } catch (error) {
          this.handleErrors(error)
        } finally {
          this.loading = false
        }
      } else {
        this.loading = false
      }
    },

    async checkDefaultProfileState() {
      if (
        !this.currentRoute ||
        !this.currentRoute.subCollections?.includes('appprescriptions')
      )
        return

      if (this.removeNotification) this.removeNotification()

      const defaultProfiles = this.getDefaultProfiles
      let errorMessage = null
      let errorCode = null

      switch (true) {
        case defaultProfiles?.length === 0:
          errorCode = PRESCRIPTION_ERROR_NO_DEFAULT_PROFILE
          break
        case defaultProfiles?.length > 1: {
          const formatedDefaultProfiles = defaultProfiles.reduce(function (
            formatedString,
            profile,
            index,
          ) {
            return (
              formatedString +
              (index === defaultProfiles.length - 1
                ? ` '${profile.label}' (${profile.id})`
                : ` '${profile.label}' (${profile.id}),`)
            )
          },
          '')
          errorMessage =
            `There is more than one default profile in this prescription: (${defaultProfiles.length})` +
            formatedDefaultProfiles
          errorCode = PRESCRIPTION_ERROR_MORE_THAN_ONE_PROFILE
          break
        }
        default:
          return
      }

      this.removeNotification = this.handleErrors({
        code: errorCode,
        message: errorMessage,
        messageInformations: { keepShowing: true, floating: true },
      })
    },

    emitLoading(loadingValue) {
      this.$emit('loading', loadingValue)
    },
  },
}
