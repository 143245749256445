<template>
  <div
    v-if="isDBLoaded && isAppRouteLoaded && hierarchy"
    class="container--edit_app"
  >
    <TitleContainer
      :title="title"
      :subtitle="subtitle"
    />

    <BaseTab
      v-if="tabsListCopy.length > 0"
      :loader="false"
      :tabs="tabsListCopy"
      link-hash-with-router
    >
      <template #actionend>
        <b-nav-item
          :href="linkToApp"
          target="_blank"
          role="presentation"
        >
          <b-icon
            icon="link"
            class="mr-2"
          />
          View app online
        </b-nav-item>
        <b-nav-item
          v-if="isPrescriptionVisible"
          :to="toPrescription"
          role="presentation"
        >
          <b-icon
            icon="arrow-right-circle-fill"
            class="mr-2"
          />
          Go to Prescription
        </b-nav-item>
      </template>
    </BaseTab>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
  import TitleContainer from '@/components/containers/TitleContainer'
  import BaseTab from '@/components/admin/BaseTab'
  import { AppMixin } from '@/mixins/AppMixin'
  import { RIGHTS } from '@/constants/UserConstants'

  const NOT_DISABLED_TAB_PROD = ['api']

  export default {
    name: 'EditApp',

    components: {
      BaseTab,
      TitleContainer,
    },

    mixins: [AppMixin],

    beforeRouteLeave(to, _from, next) {
      if (
        !['profile-creation-edition', 'prescription', 'edit-app'].includes(
          to.name,
        )
      ) {
        this.leavingPage = true
        this.resetPrescriptionState()
        this.unsetCurrentRoute()
      }
      next()
    },

    data() {
      return {
        tabsList: [],
        tabsListCopy: [],
        leavingPage: false,
      }
    },

    computed: {
      ...mapState({
        components: (state) => state.approutes.currentRoute.firecamp.components,
      }),
      ...mapGetters(['canUserDo', 'checkDependencies']),

      linkToApp() {
        if (process.env.VUE_APP_ENV === 'development') {
          return `https://wecdostodvfront.z6.web.core.windows.net/${this.appDetails.application.id}/standalone.html?tenant=${this.appDetails.tenant.id}&customer=${this.appDetails.customer.id}&country=${this.appDetails.country.id}&touchpoint=${this.appDetails.touchpoint.id}&environment=${this.appDetails.environment.id}&type=${this.appDetails.type.id}`
        }
        return this.appDetails
          ? `${this.appDetails.environment.baseUrl}standalone.html?tenant=${this.appDetails.tenant.id}&application=${this.appDetails.application.id}&customer=${this.appDetails.customer.id}&country=${this.appDetails.country.id}&environment=${this.appDetails.environment.id}&touchpoint=${this.appDetails.touchpoint.id}&type=${this.appDetails.type.id}`
          : null
      },

      title() {
        let title = null
        if (!this.appDetails) return this.$route.meta?.displayName
        const { customer, application } = this.appDetails
        title =
          [customer, application]
            .reduce((acc, current) => `${acc} ${current?.displayName}`, '')
            .trim() + ` - v${this.appDetails?.appversion?.meta?.version}`
        return title ?? this.$route.meta?.displayName
      },

      subtitle() {
        let title = null
        if (!this.appDetails) return null
        const { type, country, touchpoint, environment } = this.appDetails
        title = [type, country, touchpoint, environment].reduce(
          (acc, current, index) =>
            `${acc} ${index == 0 ? '' : '-'} ${current?.displayName}`,
          '',
        )
        return title ?? null
      },

      adminTab() {
        return [
          {
            id: 'admin',
            title: 'Admin',
            component: () => import('@/components/apps/EditAppAdmin.vue'),
          },
        ]
      },

      hasAdminTab() {
        return (
          (this.appDetails?.environment?.id === 'staging' &&
            process.env.VUE_APP_ENV !== 'development' &&
            this.canUserDo(RIGHTS.SETUP_ADMIN_DEPLOY_TO_PROD)) ||
          (this.canUserDo(RIGHTS.SETUP_ADMIN_BACKUP_AND_RESTORE) &&
            this.appDetails?.environment?.id !== 'production')
        )
      },

      exportTab() {
        return [
          {
            id: 'exports',
            title: 'Exports',
            component: () => import('@/components/apps/EditAppExport.vue'),
          },
        ]
      },

      toPrescription() {
        return {
          name: 'prescription',
          params: { app_id: this.$route.params.app_id },
        }
      },

      isEditAppLoaded() {
        return (
          !this.isAppRouteLoaded ||
          !this.hierarchy ||
          !this.appData ||
          !this.appDetails
        )
      },

      isPrescriptionVisible() {
        return this.canUserDo(RIGHTS.PRESCRIPTION)
      },
    },

    async mounted() {
      this.setLoadingInformations({
        show: true,
        title: 'Initializing application edition ...',
        bar: true,
        channel: 'editapp',
      })
      await this.bindAppRoutes()
      await this.loadCurrentRoute()
    },

    methods: {
      ...mapActions([
        'setCurrentRouteById',
        'unsetCurrentRoute',
        'bindAppRoutes',
      ]),

      ...mapMutations(['resetPrescriptionState']),

      checkSectionsDependencies() {
        this.tabsListCopy = this.tabsList.map((tab) => {
          if (tab?.props?.tabs) {
            return {
              ...tab,
              props: {
                ...tab.props,
                tabs: tab.props.tabs.filter((subSection) => {
                  const component = this.components.find(
                    ({ id }) => id === subSection.id,
                  )
                  if (component?.dependencies) {
                    return this.checkDependencies(component?.dependencies)
                  }
                  return true
                }),
              },
            }
          }
          return tab
        })
      },

      async loadCurrentRoute() {
        try {
          this.appLoading(true)
          if (
            this.isDBLoaded &&
            this.appDetails &&
            this.appDetails.id &&
            !this.isAppRouteLoaded &&
            !this.leavingPage
          ) {
            await this.setCurrentRouteById({currentRouteId: this.appDetails.id, routeName: this.$route.name})
          }

          if ([null, undefined].includes(this.appDetails)) {
						this.setLoadingInformations({ show: false, title: null, channel: 'editapp' })
						this.$router.push({ name: 'error-page', params: { from: encodeURIComponent(this.$route.path) }})
            return
          }

          if (this.isAppRouteLoaded && this.tabsList.length === 0) {
            this.tabsList = this.tabs()
            this.checkSectionsDependencies()

            this.setLoadingInformations({
              show: false,
              title: null,
              channel: 'editapp',
            })
          }
        } catch (error) {
          this.handleErrors({ error })
        } finally {
          this.appLoading(false)
        }
      },

      tabs() {
        return this.isDBLoaded && this.isAppRouteLoaded
          ? [
              ...(this.hierarchy ? this.hierarchyTabs() : []),
              ...(this.appData && this.canUserDo(RIGHTS.SETUP_UNMAPPED)
                ? this.unmappedTab()
                : []),
              ...(this.appData && this.canUserDo(RIGHTS.SETUP_EXPORTS)
                ? this.exportTab
                : []),
              ...(this.hasAdminTab ? this.adminTab : []),
            ]
          : []
      },

      unmappedTab() {
        return (
          [
            {
              id: 'Unmapped',
              title: 'Unmapped',
              component: BaseTab,
              props: {
                vertical: true,
                class: 'mt-4',
                lazy: true,
                keepAlive: true,
                loader: false,
                tabs: Object.keys(this.appData)
                  ?.map((collectionName) => {
                    const appdata = this.appData[collectionName]
                    if (typeof appdata == 'undefined') return {}
                    return {
                      id: collectionName,
                      title: collectionName,
                      component: () =>
                        import('@/components/apps/ListUnmappedFields'),
                      props: {
                        appdata,
                        components: this.fireComp,
                        collection: collectionName,
                        disabled: this.isTabDisabled(collectionName),
                      },
                    }
                  })
                  .filter((tab) => Object.keys(tab)?.length !== 0),
              },
            },
          ] ?? []
        )
      },

      hierarchyTabs() {
        return this.hierarchy?.sections?.reduce((acc, section) => {
          const permission = Object.values(RIGHTS).includes(
            'setup-' + section.id,
          )
            ? 'setup-' + section.id
            : RIGHTS.SETUP_SETTING

          if (this.canUserDo(permission)) {
            acc.push({
              id: section.id,
              title: section.label,
              component: BaseTab,
              props: {
                vertical: true,
                class: 'mt-4',
                lazy: true,
                keepAlive: true,
                loader: false,
                tabs: section?.subsections?.map((subsection) => {
                  return {
                    id: subsection.id,
                    title: subsection.label,
                    component: () => import('@/components/apps/AppEditContent'),
                    props: {
                      lazy: true,
                      keepAlive: true,
                      subsectionId: subsection.id,
                      subsectionLabel: subsection.label,
                      disabled: this.isTabDisabled(section.id),
                      checkSectionsDependencies: () =>
                        this.checkSectionsDependencies(subsection.id),
                    },
                  }
                }),
              },
            })
          }
          return acc
        }, [])
      },

      isTabDisabled(tabID) {
        return (
          this.appDetails?.environment?.id === 'production' &&
          !NOT_DISABLED_TAB_PROD.includes(tabID)
        )
      },
    },
  }
</script>
