<template>
  <div class="dashboard-prescription">
    <TitleContainer
      :title="title"
      :subtitle="subtitle"
    />
    <b-row class="dashboard-prescription_menu">
      <b-nav tabs>
        <b-nav-item
          v-for="(item, index) in menuItems"
          :key="`menu${index}`"
          :active="item.active"
          :to="{ name: item.routeName }"
        >
          <b-spinner
            v-if="item.active && !isPrescriptionLoaded"
            small
          />
          {{ item.title }}
        </b-nav-item>
        <b-nav-item
          v-if="isSetupVisible"
          :to="{ name: 'edit-app' }"
          role="presentation"
        >
          <b-icon
            icon="arrow-right-circle-fill"
            class="mr-2"
          />
          Go to Setup
        </b-nav-item>
      </b-nav>
    </b-row>
    <router-view />
  </div>
</template>

<script>
  import { PrescriptionMixin } from '@/mixins/PrescriptionMixin.js'
  import { RIGHTS } from '@/constants/UserConstants'
  import { mapGetters } from 'vuex'
  import TitleContainer from '@/components/containers/TitleContainer'

  export default {
    name: 'DashboardPrescription',

    components: {
      TitleContainer,
    },

    mixins: [PrescriptionMixin],

    computed: {
      ...mapGetters(['canUserDo', 'products/getProductInstance']),

      menuItems() {
        const menuItems = []
        if (
          !this['products/getProductInstance'] &&
          this.canUserDo(RIGHTS.PRESCRIPTION_PRODUCT_LIST)
        ) {
          menuItems.push({
            title: 'Product List',
            routeName: 'prescription-product-list',
            useParentRoute: 'products-list',
          })
        }

        if (
          this['products/getProductInstance'] &&
          this.canUserDo(RIGHTS.IGNORE_MAINTENANCE_MODE)
        ) {
          menuItems.push({
            title: 'Product List',
            routeName: 'prescription-product-list-new',
            useParentRoute: 'products-list-new',
          })
        }

        if (this.canUserDo(RIGHTS.PRESCRIPTION_PROFILES_LIST)) {
          menuItems.push({
            title: 'Profiles Lists',
            routeName: 'prescription-profile-list',
          })
        }

        if (
          !this['products/getProductInstance'] &&
          this.canUserDo(RIGHTS.PRESCRIPTION_ITEMS_LIST)
        ) {
          menuItems.push({
            title: 'Items Lists',
            routeName: 'prescription-item-list',
          })
        }

        if (this.canUserDo(RIGHTS.PRESCRIPTION_SETTINGS)) {
          menuItems.push({
            title: 'Settings',
            routeName: 'prescription-settings',
          })
        }

        return menuItems.map((item) => {
          item.active = this.$route.matched.some(({ name, parent }) => {
            if (item.useParentRoute) {
              return item.useParentRoute === parent?.name
            } else {
              return name === item.routeName
            }
          })
          return item
        })
      },

      isSetupVisible() {
        return this.canUserDo(RIGHTS.SETUP)
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/mixins/nav-tabs.scss';
  .dashboard-prescription_menu {
    @include nav-tabs;
  }
</style>
